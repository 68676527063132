<template>
  <div
    class="order-card"
    :class="{ selected: isSelected }"
    @click="onPrescriptionClick"
  >
    <div class="p-3 d-flex justify-content-between">
      <div>
        <p class="h2 mb-2">
          {{ prescription.medicinename }} | {{ prescription.dosage }}
        </p>
        <small class="text-muted">{{ prescription.unit }}</small>
      </div>
      <div class="h5" style="color: #a783ff">Order Processing</div>
    </div>
    <template
      v-if="
        prescription.invoice_amount_paid !== null &&
        prescription.invoice_subtotal !== null
      "
    >
      <template
        v-if="
          prescription.invoice_amount_paid === prescription.invoice_subtotal
        "
      >
        <div class="p-3">${{ prescription.amount.toFixed(2) }}</div>
      </template>
      <template v-else>
        <div class="p-3">
          <span class="strikethrough mr-1"
            >${{ prescription.invoice_subtotal / 100 }}</span
          ><span>${{ prescription.invoice_amount_paid / 100 }}</span>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="p-3">${{ prescription.amount.toFixed(2) }}</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    prescription: {
      type: Object,
      default: () => ({}),
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onPrescriptionClick() {
      this.$emit("selected", {
        prescriptionid: this.prescription.id,
        productType: this.prescription.productType,
      });
    },
  },
};
</script>

<style scoped>
.order-card {
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 125px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .order-card {
    height: 150px;
  }
}

.order-card.selected {
  border: 2px solid #a783ff;
  background-color: #f6f2ff;
}

.strikethrough {
  text-decoration: line-through;
}
</style>
