<template>
  <div class="p-4 support-card" @click="onCardClick" v-if="step === 1">
    <h1>{{ item.title }}</h1>
    <p class="mb-0">{{ item.description }}</p>
  </div>
  <div class="p-4 support-card step-two" @click="onCardClick" v-else>
    <div style="padding-right: 0.5rem">
      <h1>{{ item.title }}</h1>
      <p class="mb-0">{{ item.description }}</p>
    </div>
    <div>
      <img src="@/assets/images/next-arrow.svg" width="30" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    item: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        subList: [],
      }),
    },
  },
  methods: {
    onCardClick() {
      if (this.step === 1) {
        if (this.item.title === "Knowledge Hub") {
          window.open("https://help.bywinona.com/en/", "_blank");
          return;
        }

        this.$emit("change-view", {
          title: this.item.title,
          subList: this.item.subList,
        });
      } else if (this.step === 2) {
        if (this.item.title === "Manage my subscriptions") {
          this.$router.push("/treatment");
          return;
        } else if (this.item.title == "Update credit card") {
          this.$router.push({ name: "billing-details" });
        } else if (this.item.title == "Update billing address") {
          this.$router.push({ name: "billing-address" });
        } else if (this.item.title == "Update shipping address") {
          this.$router.push({ name: "shipping-address" });
        } else if (this.item.title == "Go to my Insurance Hub") {
          this.$router.push({ name: "insurance-documents" });
        } else if (this.item.title == "Update my email") {
          this.$router.push({ name: "update-email" });
        } else if (this.item.title == "Download Receipts") {
          this.$emit("hsa-receipt");
        }

        this.$emit("open-modal", this.item.title);
      }
    },
  },
};
</script>

<style scoped>
.support-card {
  background-color: #f5f2ff;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  height: 100%;
}

.support-card.step-two {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.anchor-text {
  color: #19191c;

  &:hover {
    color: #19191c;
  }
}
</style>
