<template>
  <div>
    <div class="text-right">
      <a href="javascript:;" @click="$bvModal.hide('message-doctor-form')">
        <b-icon icon="x" variant="link" font-scale="1.5"></b-icon>
      </a>
    </div>

    <div class="px-2 pt-2 pb-4">
      <div v-if="isFinished" class="mx-auto" style="max-width: 768px">
        <p class="h2 text-uppercase my-2" style="letter-spacing: 0.1rem">
          Thank You
        </p>
        <p>
          Your request has been received and your doctor will reach out about
          adjusting your treatment to better fit your needs.
        </p>
        <b-button
          variant="info"
          pill
          block
          class="mt-4"
          @click="$bvModal.hide('message-doctor-form')"
        >
          Close
        </b-button>
      </div>
      <div v-else class="mx-auto" style="max-width: 768px">
        <slot name="title"></slot>
        <p>
          Please message your physician here sharing details on how your
          treatment could better serve you.
        </p>
        <b-alert
          v-model="alert.show"
          :variant="alert.type"
          dismissible
          @dismissed="alert.show = false"
        >
          {{ alert.message }}
        </b-alert>
        <b-form-textarea
          id="textarea"
          class="mx-auto"
          v-model.trim="message"
          rows="6"
          max-rows="6"
          style="
            overflow: auto;
            border: 1px solid #19191c;
            border-radius: 0.25rem;
          "
        ></b-form-textarea>
        <b-button
          variant="info"
          pill
          block
          class="mt-4"
          @click="onSendMessage"
          :disabled="isProcessing || !conversation.id"
        >
          <b-spinner small v-if="isProcessing"></b-spinner>
          <span v-else>Send</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { ChatService } from "@/services/chat.service.js";

export default {
  props: {
    issue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      message: "",
      alert: {
        show: false,
        type: "warning",
        message: "",
      },
      isFinished: false,
      isProcessing: false,
      conversation: {
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    ...mapGetters("configuration", ["isUserSupportsAdminPatientChat"]),
  },
  async created() {
    const { data: conversation } = await ChatService.listConversation();
    if (conversation.length > 0) {
      this.conversation = conversation[0];
    }
  },
  methods: {
    showAlert(message) {
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
    },
    async onSendMessage() {
      try {
        if (!this.message) {
          this.showAlert("Kindly fill up all the fields");
          return;
        }
        this.isProcessing = true;
        await ChatService.sendMessage({
          id: this.conversation.id,
          message: this.message,
        });
        this.message = "";

        if (this.isUserSupportsAdminPatientChat) {
          this.$router.push({ name: "message-doctor" });
        } else {
          this.$router.push({ name: "messages" });
          this.isFinished = true;
        }
      } catch (error) {
        this.showAlert(error?.message);
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>

<style></style>
