<template>
  <div>
    <div class="text-right">
      <a href="javascript:;" @click="$bvModal.hide('contact-us-form')">
        <b-icon icon="x" variant="link" font-scale="1.5"></b-icon>
      </a>
    </div>

    <div class="px-2 pt-2 pb-4">
      <div v-if="isFinished" class="mx-auto" style="max-width: 768px">
        <p class="support-modal-title text-bold my-2">Thank You</p>
        <p>
          Your request has been received and our Patient Care team will contact
          you via email in 24 hours.
        </p>
        <b-button
          variant="info"
          pill
          block
          class="mt-4"
          @click="$bvModal.hide('contact-us-form')"
        >
          Close
        </b-button>
      </div>
      <div v-else class="mx-auto" style="max-width: 768px">
        <p class="support-modal-title text-bold my-2">Ask the care team</p>
        <p>
          Please share details about your request with our Patient Care team.
        </p>
        <b-alert
          v-model="alert.show"
          :variant="alert.type"
          dismissible
          @dismissed="alert.show = false"
        >
          {{ alert.message }}
        </b-alert>
        <b-form-textarea
          id="textarea"
          class="mx-auto"
          v-model.trim="message"
          rows="6"
          max-rows="6"
          style="
            overflow: auto;
            border: 1px solid #19191c;
            border-radius: 0.25rem;
          "
        />
        <b-button variant="info" pill block class="mt-4" @click="onSendEmail">
          Continue
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ContactService } from "@/services/contact.service";

export default {
  props: {
    subject: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("configuration", ["config"]),

    ...mapState("user", {
      user: "currentUser",
    }),
  },
  data() {
    return {
      message: "",
      alert: {
        show: false,
        type: "warning",
        message: "",
      },
      isFinished: false,
    };
  },
  methods: {
    showAlert(message) {
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
    },
    async onSendEmail() {
      try {
        if (!this.message) {
          this.showAlert("Kindly fill up all the fields");
          return;
        }
        const statesForAdminChat = this.config.admin_chat;
        const showAdminChat = statesForAdminChat.includes(this.user.state);

        if (showAdminChat) {
          this.$store.commit("support/SET_MESSAGE_PREFIX", "");
          this.$store.commit("support/SET_MESSAGE_TO_ADMIN", this.message);
          this.message = "";
          this.$router.push({ name: "message-admin" });
        } else {
          await ContactService.sendEmail({
            subject:
              this.subject === "Ask us Anything" ? "Other" : this.subject,
            body: this.message,
          });
          this.isFinished = true;
        }
      } catch (error) {
        this.showAlert(error?.message);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.support-modal-title {
  font-size: 24px;
  font-weight: 700;
}
</style>
