<template>
  <div
    class="icons-item"
    :class="{ selected: isSelected }"
    @click="onSelection"
  >
    <v-icon
      :name="item.id"
      color="#19191c"
      :size="['acne'].includes(item.id) ? '50' : '35'"
    ></v-icon>
    <small class="d-inline-flex mt-2">{{ item.name }}</small>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelection() {
      this.$emit("selected", this.item);
    },
  },
};
</script>

<style scoped>
.icons-item {
  cursor: pointer;
  border-radius: 0.25rem;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid #e2e8f0;
}

.icons-item.selected {
  border: 1px solid #a783ff;
  background-color: #f6f2ff;
}
</style>
