<template>
  <div class="p-2">
    <div v-if="isFinished" class="mx-auto" style="max-width: 768px">
      <p class="h2 text-uppercase my-2" style="letter-spacing: 0.1rem">Done!</p>
      <p>
        Your orders have been cancelled and the amount refunded to your card on
        file.
      </p>
      <b-button
        variant="info"
        pill
        block
        class="mt-4"
        @click="$bvModal.hide('cancel-processing-order')"
      >
        Close
      </b-button>
    </div>
    <div v-else class="mx-auto" style="max-width: 768px">
      <p class="h2 text-uppercase my-2" style="letter-spacing: 0.1rem">
        Cancel Processing Order
      </p>
      <template v-if="hasLoaded && this.prescriptions.length > 0">
        <p>Select each processing order you would like to cancel</p>
        <b-alert
          v-model="alert.show"
          :variant="alert.type"
          dismissible
          @dismissed="alert.show = false"
        >
          {{ alert.message }}
        </b-alert>
        <div class="spacer">
          <processing-order-card
            v-for="prescription in prescriptions"
            :key="prescription.id"
            :prescription="prescription"
            :is-selected="selectedPrescriptions.includes(prescription.id)"
            @selected="onSelected"
          />
        </div>
        <div v-if="isReasoningVisible" class="mt-3" style="line-height: 1.25">
          <small class="text-muted">
            Estrogen &amp; Progesterone must be sent together.<br />
            If you would like to only cancel one please message support.
          </small>
        </div>
      </template>
      <div
        v-else-if="hasLoaded && this.prescriptions.length === 0"
        class="my-4"
      >
        <p class="mb-0">You currently have no processing orders</p>
        <small class="text-muted">
          It's possible your order has already been packaged. Please contact
          patient services for further support.
        </small>
      </div>
      <template v-else>
        <p>Fetching your orders...</p>
      </template>
      <b-button
        v-if="hasLoaded && this.prescriptions.length > 0"
        variant="info"
        pill
        block
        class="mt-4"
        @click="onCancelOrder"
        :disabled="isProcessing"
      >
        <b-spinner small v-if="isProcessing"></b-spinner>
        <span v-else>
          <template v-if="selectedPrescriptions.length > 0">
            Cancel {{ selectedPrescriptions.length }}
            {{ pluralize(selectedPrescriptions.length, "Order") }}
          </template>
          <template v-else>Cancel Order</template>
        </span>
      </b-button>
      <b-button
        v-if="hasLoaded && this.prescriptions.length > 0"
        variant="outline-info"
        pill
        block
        class="mt-3"
        @click="$bvModal.hide('cancel-processing-order')"
        :disabled="isProcessing"
      >
        Nevermind
      </b-button>
      <b-button
        v-if="hasLoaded && this.prescriptions.length === 0"
        variant="info"
        pill
        block
        class="mt-4"
        @click="$bvModal.hide('cancel-processing-order')"
      >
        <span>Close</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ContactService } from "@/services/contact.service";
import { PrescriptionService } from "@/services/prescription.service";

import ProcessingOrderCard from "@/components/support/components/ProcessingOrderCard.vue";

export default {
  props: {
    subject: {
      type: String,
      default: "",
    },
  },
  components: {
    ProcessingOrderCard,
  },
  data() {
    return {
      alert: {
        show: false,
        type: "warning",
        message: "",
      },
      isProcessing: false,
      isFinished: false,
      hasLoaded: false,
      prescriptions: [],
      selectedPrescriptions: [],

      isReasoningVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    showAlert(message) {
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
    },
    async onCancelOrder() {
      try {
        if (this.selectedPrescriptions.length === 0) {
          this.showAlert("Please select a prescription");
          return;
        }
        this.isProcessing = true;
        await PrescriptionService.cancelProcessingOrder(
          this.user.id,
          this.selectedPrescriptions
        );
        this.isFinished = true;
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        this.showAlert(error?.message);
      }
    },
    async getPrescriptions() {
      try {
        let { data } = await PrescriptionService.getAllPrescriptionOrders();
        this.hasLoaded = true;
        this.prescriptions = data?.filter(
          (o) =>
            o?.airtablestatus === "Processing" &&
            o?.prescriptionstatus !== "CANCELLED"
        );
      } catch (err) {
        this.hasLoaded = false;

        if (err.message) {
          console.log(err.message);
        }
      }
    },
    onSelected(prescription) {
      if (prescription.productType === "Progesterone") {
        this.isReasoningVisible = true;
        if (!this.selectedPrescriptions.includes(prescription.prescriptionid)) {
          for (const item of this.prescriptions) {
            if (
              item.productType === "Estrogen" &&
              !this.selectedPrescriptions.includes(item.id)
            ) {
              this.selectedPrescriptions.push(item.id);
            }

            if (
              item.productType === "Progesterone" &&
              !this.selectedPrescriptions.includes(item.id)
            ) {
              this.selectedPrescriptions.push(item.id);
            }
          }
        }
      } else if (prescription.productType === "Estrogen") {
        this.isReasoningVisible = false;
        const index = this.selectedPrescriptions.indexOf(
          prescription.prescriptionid
        );
        if (index !== -1) {
          this.selectedPrescriptions.splice(index, 1);

          // If there's no estrogen left, empty the array
          let estrogenCounter = 0;
          let progesteroneCounter = 0;

          for (const item of this.selectedPrescriptions) {
            const foundItem = this.prescriptions.find((val) => val.id === item);
            if (foundItem?.productType === "Estrogen") {
              estrogenCounter += 1;
            } else if (foundItem?.productType === "Progesterone") {
              progesteroneCounter += 1;
            }
          }

          if (estrogenCounter === 0 && progesteroneCounter > 0) {
            const noProductType = [];
            for (const item of this.prescriptions) {
              if (
                item.productType === "" &&
                this.selectedPrescriptions.includes(item.id)
              ) {
                noProductType.push(item.id);
              }
            }
            this.selectedPrescriptions = noProductType;
          }
        } else {
          this.selectedPrescriptions.push(prescription.prescriptionid);

          const estrogenList = this.prescriptions.filter(
            (val) => val.productType === "Estrogen"
          );
          let estrogenCounter = 0;
          let progesteroneCounter = 0;
          for (const item of this.selectedPrescriptions) {
            const foundItem = this.prescriptions.find((val) => val.id === item);
            if (foundItem?.productType === "Estrogen") {
              estrogenCounter += 1;
            } else if (foundItem?.productType === "Progesterone") {
              progesteroneCounter += 1;
            }
          }

          if (
            estrogenList.length === estrogenCounter &&
            progesteroneCounter > 0
          ) {
            this.selectedPrescriptions = this.prescriptions.map(
              (val) => val.id
            );
            this.isReasoningVisible = true;
          }
        }
      } else {
        this.isReasoningVisible = false;
        const index = this.selectedPrescriptions.indexOf(
          prescription.prescriptionid
        );
        if (index !== -1) {
          this.selectedPrescriptions.splice(index, 1);
        } else {
          this.selectedPrescriptions.push(prescription.prescriptionid);
        }
      }
    },
    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    },
  },
  async created() {
    await this.getPrescriptions();
  },
};
</script>

<style scoped>
.spacer > * + * {
  margin-top: 1rem;
}
</style>
