<template>
  <div>
    <div class="text-right">
      <a href="javascript:;" @click="$bvModal.hide('manage-treatment-screen')">
        <b-icon icon="x" variant="link" font-scale="1.5"></b-icon>
      </a>
    </div>

    <div class="px-2 pt-2 pb-4">
      <div v-if="isFinished" class="mx-auto" style="max-width: 768px">
        <p class="h2 text-uppercase my-2" style="letter-spacing: 0.1rem">
          Thank You
        </p>
        <p>
          Your request has been received and your doctor will reach out about
          adjusting your treatment to better fit your needs.
        </p>
        <b-button
          variant="info"
          pill
          block
          class="mt-4"
          @click="$bvModal.hide('manage-treatment-screen')"
        >
          Close
        </b-button>
      </div>
      <div v-else class="mx-auto" style="max-width: 768px">
        <slot name="title"></slot>
        <template v-if="items.length > 0">
          <p>Please select all that apply</p>
          <b-alert
            v-model="alert.show"
            :variant="alert.type"
            dismissible
            @dismissed="alert.show = false"
          >
            {{ alert.message }}
          </b-alert>
          <div class="icons-container">
            <manage-treatment-select
              v-for="item in items"
              :key="item.id"
              :item="item"
              @selected="onSelected"
              :is-selected="selected.includes(item.name)"
            />
          </div>
          <b-form-textarea
            id="textarea"
            class="mx-auto"
            placeholder="Anything else to add?"
            v-model.trim="message"
            rows="3"
            max-rows="3"
            style="
              overflow: auto;
              border: 1px solid #19191c;
              border-radius: 0.25rem;
            "
          ></b-form-textarea>
          <b-button
            variant="info"
            pill
            block
            class="mt-4"
            @click="onSendMessage"
            :disabled="isProcessing || !conversation.id"
          >
            <b-spinner small v-if="isProcessing"></b-spinner>
            <span v-else>Send</span>
          </b-button>
        </template>
        <template v-else>
          <p>
            Fetching
            {{ type === "sideeffect" ? "side effects" : "symptoms" }}...
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { ChatService } from "@/services/chat.service.js";
import { LookUpService } from "@/services/lookup.service.js";

import ManageTreatmentSelect from "@/components/support/components/ManageTreatmentSelect.vue";

export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    ManageTreatmentSelect,
  },
  data() {
    return {
      message: "",
      alert: {
        show: false,
        type: "warning",
        message: "",
      },
      isFinished: false,
      isProcessing: false,
      conversation: {
        id: "",
      },

      items: [],
      selected: [],
    };
  },
  computed: {
    ...mapState("configuration", ["config"]),
    ...mapGetters({
      user: "user/getUser",
    }),
    ...mapGetters("configuration", ["isUserSupportsAdminPatientChat"]),
    joinedSelectedList() {
      return this.selected.join(", ");
    },
  },
  async created() {
    const { data } = await LookUpService.getLookUp(this.type);
    this.items = data;

    const { data: conversation } = await ChatService.listConversation();
    if (conversation.length > 0) {
      this.conversation = conversation[0];
    }
  },
  methods: {
    showAlert(message) {
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
    },
    async onSendMessage() {
      try {
        if (this.selected.length === 0) {
          this.showAlert("Kindly select an option");
          return;
        }
        this.isProcessing = true;

        let message;

        if (this.type === "sideeffect") {
          message = `Hi I'm experiencing the following side effects: \n ${this.joinedSelectedList} \n ${this.message}`;
        } else {
          message = `Hi I'm experiencing the following unmet symptoms: \n ${this.joinedSelectedList} \n ${this.message}`;
        }

        await ChatService.sendMessage({
          id: this.conversation.id,
          message,
        });
        this.message = "";

        const statesForAdminChat = this.config.admin_chat;
        const adminChatEnabled = statesForAdminChat.includes(this.user.state);
        if (adminChatEnabled) {
          this.$router.push({ name: "message-doctor" });
        } else {
          this.$router.push({ name: "messages" });
        }

        this.isFinished = true;
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        this.showAlert(error?.message);
      } finally {
        this.isProcessing = false;
      }
    },
    onSelected(item) {
      if (this.selected.includes(item.name)) {
        const index = this.selected.indexOf(item.name);
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      } else {
        this.selected.push(item.name);
      }
    },
  },
};
</script>

<style scoped>
.icons-container {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.icons-container div {
  width: calc(20% - 0.75rem);
}

@media (max-width: 1024px) {
  .icons-container div {
    width: calc(33.33% - 0.75rem);
  }
}

@media (max-width: 768px) {
  .icons-container div {
    width: calc(50% - 0.75rem);
  }
}
</style>
