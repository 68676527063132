var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "p-0" },
    [
      _c("div", { staticClass: "pt-6" }),
      _c(
        "b-toast",
        {
          attrs: {
            id: "alertToast",
            variant: "danger",
            "no-close-button": "",
            "auto-hide-delay": "2500",
            toaster: "b-toaster-top-center",
            solid: "",
          },
        },
        [_vm._v(" " + _vm._s(_vm.toastMessage) + " ")]
      ),
      _c("HSAReceiptDialog", {
        ref: "HSAReceiptDialog",
        attrs: {
          id: _vm.user.id,
          name: `${_vm.user.firstname} ${_vm.user.lastname}`,
        },
      }),
      _vm.step === 1
        ? _c(
            "h2",
            { staticClass: "text-center mb-6 display-2 font-editorial" },
            [_vm._v(" Patient Care ")]
          )
        : _vm._e(),
      ![1, 3].includes(_vm.step)
        ? _c(
            "div",
            { staticClass: "mb-5 px-3" },
            [
              _c("p", { staticClass: "text-center display-4 text-bold" }, [
                _vm._v(" " + _vm._s(_vm.mainCardTitle) + " "),
              ]),
              _c(
                "b-button",
                {
                  staticClass: "back-btn d-flex align-items-center",
                  staticStyle: { "min-width": "inherit" },
                  attrs: { size: "sm", variant: "link" },
                  on: {
                    click: function ($event) {
                      _vm.step -= 1
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { transform: "rotate(180deg)" },
                    attrs: {
                      src: require("@/assets/images/next-arrow.svg"),
                      width: "20",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "d-inline-block ml-2",
                      staticStyle: { color: "#19191c" },
                    },
                    [_vm._v("Back")]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      [
        _vm.step === 1
          ? _c(
              "div",
              { staticClass: "support-container pb-3 px-2" },
              [
                _vm._l(_vm.supportList, function (item) {
                  return [
                    (item.title === "Manage my Treatment" &&
                      _vm.hasCompletedTenWeekFollowup) ||
                    item.title !== "Manage my Treatment"
                      ? _c("MainSupportCard", {
                          key: item.title,
                          attrs: { step: _vm.step, item: item },
                          on: {
                            "change-view": _vm.onChangeView,
                            "hsa-receipt": _vm.openHSAReceipt,
                          },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            )
          : _vm.step === 2
          ? _c(
              "div",
              { staticClass: "support-container pb-3 px-3" },
              _vm._l(_vm.subSupportList, function (item) {
                return _c(
                  "div",
                  { key: item.title },
                  [
                    _c("MainSupportCard", {
                      attrs: { step: _vm.step, item: item },
                      on: {
                        "open-modal": _vm.onOpenModal,
                        "hsa-receipt": _vm.openHSAReceipt,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ],
      _c(
        "b-modal",
        _vm._b(
          { attrs: { id: "contact-us-form" } },
          "b-modal",
          _vm.modalAttrs,
          false
        ),
        [_c("contact-us-form", { attrs: { subject: _vm.mainCardTitle } })],
        1
      ),
      _c(
        "b-modal",
        _vm._b(
          { attrs: { id: "cancel-processing-order" } },
          "b-modal",
          _vm.modalAttrs,
          false
        ),
        [
          _c("cancel-processing-order-screen", {
            attrs: { subject: _vm.mainCardTitle },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        _vm._b(
          { attrs: { id: "report-issue-form" } },
          "b-modal",
          _vm.modalAttrs,
          false
        ),
        [
          _c("report-issue-form", {
            attrs: { issue: _vm.issue, "issue-title": _vm.selectedIssue },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm.selectedIssue === `I'd like to report an issue`
                      ? _c(
                          "p",
                          { staticClass: "support-modal-title text-bold my-2" },
                          [_vm._v(" Report issue ")]
                        )
                      : _vm.selectedIssue ===
                          `I have an issue with my delivery` ||
                        _vm.selectedIssue === "Report delivery issue"
                      ? _c(
                          "p",
                          { staticClass: "support-modal-title text-bold my-2" },
                          [_vm._v(" Report issue with my delivery ")]
                        )
                      : _vm.selectedIssue === `I have an issue with my doctor`
                      ? _c(
                          "p",
                          { staticClass: "support-modal-title text-bold my-2" },
                          [_vm._v(" Report issue with my doctor ")]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        _vm._b(
          { attrs: { id: "message-doctor-form" } },
          "b-modal",
          _vm.modalAttrs,
          false
        ),
        [
          _c("message-doctor-form", {
            attrs: { issue: _vm.selectedIssue },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm.selectedIssue === "I have a question for my doctor"
                      ? _c(
                          "p",
                          { staticClass: "support-modal-title text-bold my-2" },
                          [_vm._v(" I have question for my doctor ")]
                        )
                      : _vm.selectedIssue === `I'd like to change my treatment`
                      ? _c(
                          "p",
                          { staticClass: "support-modal-title text-bold my-2" },
                          [_vm._v(" I'd like to change my treatment ")]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        _vm._b(
          { attrs: { id: "manage-treatment-screen" } },
          "b-modal",
          _vm.modalAttrs,
          false
        ),
        [
          _c("manage-treatment-screen", {
            attrs: {
              type:
                _vm.selectedIssue === `I'm experiencing side effects`
                  ? "sideeffect"
                  : "symptom",
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm.selectedIssue === `I'm experiencing side effects`
                      ? _c(
                          "p",
                          { staticClass: "support-modal-title text-bold my-2" },
                          [_vm._v(" What are your side effects? ")]
                        )
                      : _vm.selectedIssue === `I have unmet symptoms`
                      ? _c(
                          "p",
                          { staticClass: "support-modal-title text-bold my-2" },
                          [_vm._v(" What are your unmet symptoms? ")]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }