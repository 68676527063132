var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "text-right" }, [
      _c(
        "a",
        {
          attrs: { href: "javascript:;" },
          on: {
            click: function ($event) {
              return _vm.$bvModal.hide("manage-treatment-screen")
            },
          },
        },
        [
          _c("b-icon", {
            attrs: { icon: "x", variant: "link", "font-scale": "1.5" },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "px-2 pt-2 pb-4" }, [
      _vm.isFinished
        ? _c(
            "div",
            { staticClass: "mx-auto", staticStyle: { "max-width": "768px" } },
            [
              _c(
                "p",
                {
                  staticClass: "h2 text-uppercase my-2",
                  staticStyle: { "letter-spacing": "0.1rem" },
                },
                [_vm._v(" Thank You ")]
              ),
              _c("p", [
                _vm._v(
                  " Your request has been received and your doctor will reach out about adjusting your treatment to better fit your needs. "
                ),
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "info", pill: "", block: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("manage-treatment-screen")
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "mx-auto", staticStyle: { "max-width": "768px" } },
            [
              _vm._t("title"),
              _vm.items.length > 0
                ? [
                    _c("p", [_vm._v("Please select all that apply")]),
                    _c(
                      "b-alert",
                      {
                        attrs: { variant: _vm.alert.type, dismissible: "" },
                        on: {
                          dismissed: function ($event) {
                            _vm.alert.show = false
                          },
                        },
                        model: {
                          value: _vm.alert.show,
                          callback: function ($$v) {
                            _vm.$set(_vm.alert, "show", $$v)
                          },
                          expression: "alert.show",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "icons-container" },
                      _vm._l(_vm.items, function (item) {
                        return _c("manage-treatment-select", {
                          key: item.id,
                          attrs: {
                            item: item,
                            "is-selected": _vm.selected.includes(item.name),
                          },
                          on: { selected: _vm.onSelected },
                        })
                      }),
                      1
                    ),
                    _c("b-form-textarea", {
                      staticClass: "mx-auto",
                      staticStyle: {
                        overflow: "auto",
                        border: "1px solid #19191c",
                        "border-radius": "0.25rem",
                      },
                      attrs: {
                        id: "textarea",
                        placeholder: "Anything else to add?",
                        rows: "3",
                        "max-rows": "3",
                      },
                      model: {
                        value: _vm.message,
                        callback: function ($$v) {
                          _vm.message =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "message",
                      },
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          variant: "info",
                          pill: "",
                          block: "",
                          disabled: _vm.isProcessing || !_vm.conversation.id,
                        },
                        on: { click: _vm.onSendMessage },
                      },
                      [
                        _vm.isProcessing
                          ? _c("b-spinner", { attrs: { small: "" } })
                          : _c("span", [_vm._v("Send")]),
                      ],
                      1
                    ),
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        " Fetching " +
                          _vm._s(
                            _vm.type === "sideeffect"
                              ? "side effects"
                              : "symptoms"
                          ) +
                          "... "
                      ),
                    ]),
                  ],
            ],
            2
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }