var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-2" }, [
    _vm.isFinished
      ? _c(
          "div",
          { staticClass: "mx-auto", staticStyle: { "max-width": "768px" } },
          [
            _c(
              "p",
              {
                staticClass: "h2 text-uppercase my-2",
                staticStyle: { "letter-spacing": "0.1rem" },
              },
              [_vm._v("Done!")]
            ),
            _c("p", [
              _vm._v(
                " Your orders have been cancelled and the amount refunded to your card on file. "
              ),
            ]),
            _c(
              "b-button",
              {
                staticClass: "mt-4",
                attrs: { variant: "info", pill: "", block: "" },
                on: {
                  click: function ($event) {
                    return _vm.$bvModal.hide("cancel-processing-order")
                  },
                },
              },
              [_vm._v(" Close ")]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "mx-auto", staticStyle: { "max-width": "768px" } },
          [
            _c(
              "p",
              {
                staticClass: "h2 text-uppercase my-2",
                staticStyle: { "letter-spacing": "0.1rem" },
              },
              [_vm._v(" Cancel Processing Order ")]
            ),
            _vm.hasLoaded && this.prescriptions.length > 0
              ? [
                  _c("p", [
                    _vm._v(
                      "Select each processing order you would like to cancel"
                    ),
                  ]),
                  _c(
                    "b-alert",
                    {
                      attrs: { variant: _vm.alert.type, dismissible: "" },
                      on: {
                        dismissed: function ($event) {
                          _vm.alert.show = false
                        },
                      },
                      model: {
                        value: _vm.alert.show,
                        callback: function ($$v) {
                          _vm.$set(_vm.alert, "show", $$v)
                        },
                        expression: "alert.show",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "spacer" },
                    _vm._l(_vm.prescriptions, function (prescription) {
                      return _c("processing-order-card", {
                        key: prescription.id,
                        attrs: {
                          prescription: prescription,
                          "is-selected": _vm.selectedPrescriptions.includes(
                            prescription.id
                          ),
                        },
                        on: { selected: _vm.onSelected },
                      })
                    }),
                    1
                  ),
                  _vm.isReasoningVisible
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-3",
                          staticStyle: { "line-height": "1.25" },
                        },
                        [_vm._m(0)]
                      )
                    : _vm._e(),
                ]
              : _vm.hasLoaded && this.prescriptions.length === 0
              ? _c("div", { staticClass: "my-4" }, [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("You currently have no processing orders"),
                  ]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      " It's possible your order has already been packaged. Please contact patient services for further support. "
                    ),
                  ]),
                ])
              : [_c("p", [_vm._v("Fetching your orders...")])],
            _vm.hasLoaded && this.prescriptions.length > 0
              ? _c(
                  "b-button",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      variant: "info",
                      pill: "",
                      block: "",
                      disabled: _vm.isProcessing,
                    },
                    on: { click: _vm.onCancelOrder },
                  },
                  [
                    _vm.isProcessing
                      ? _c("b-spinner", { attrs: { small: "" } })
                      : _c(
                          "span",
                          [
                            _vm.selectedPrescriptions.length > 0
                              ? [
                                  _vm._v(
                                    " Cancel " +
                                      _vm._s(_vm.selectedPrescriptions.length) +
                                      " " +
                                      _vm._s(
                                        _vm.pluralize(
                                          _vm.selectedPrescriptions.length,
                                          "Order"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              : [_vm._v("Cancel Order")],
                          ],
                          2
                        ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasLoaded && this.prescriptions.length > 0
              ? _c(
                  "b-button",
                  {
                    staticClass: "mt-3",
                    attrs: {
                      variant: "outline-info",
                      pill: "",
                      block: "",
                      disabled: _vm.isProcessing,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$bvModal.hide("cancel-processing-order")
                      },
                    },
                  },
                  [_vm._v(" Nevermind ")]
                )
              : _vm._e(),
            _vm.hasLoaded && this.prescriptions.length === 0
              ? _c(
                  "b-button",
                  {
                    staticClass: "mt-4",
                    attrs: { variant: "info", pill: "", block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$bvModal.hide("cancel-processing-order")
                      },
                    },
                  },
                  [_c("span", [_vm._v("Close")])]
                )
              : _vm._e(),
          ],
          2
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", { staticClass: "text-muted" }, [
      _vm._v(" Estrogen & Progesterone must be sent together."),
      _c("br"),
      _vm._v(" If you would like to only cancel one please message support. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }