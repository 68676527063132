var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.step === 1
    ? _c(
        "div",
        { staticClass: "p-4 support-card", on: { click: _vm.onCardClick } },
        [
          _c("h1", [_vm._v(_vm._s(_vm.item.title))]),
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm.item.description)),
          ]),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "p-4 support-card step-two",
          on: { click: _vm.onCardClick },
        },
        [
          _c("div", { staticStyle: { "padding-right": "0.5rem" } }, [
            _c("h1", [_vm._v(_vm._s(_vm.item.title))]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.item.description)),
            ]),
          ]),
          _vm._m(0),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: { src: require("@/assets/images/next-arrow.svg"), width: "30" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }