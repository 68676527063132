import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class ContactService extends BaseService {
  static async sendEmail(data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/contact`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async reportIssue(patientid, data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/patient/${patientid}/report`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
