var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "order-card",
      class: { selected: _vm.isSelected },
      on: { click: _vm.onPrescriptionClick },
    },
    [
      _c("div", { staticClass: "p-3 d-flex justify-content-between" }, [
        _c("div", [
          _c("p", { staticClass: "h2 mb-2" }, [
            _vm._v(
              " " +
                _vm._s(_vm.prescription.medicinename) +
                " | " +
                _vm._s(_vm.prescription.dosage) +
                " "
            ),
          ]),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.prescription.unit)),
          ]),
        ]),
        _c("div", { staticClass: "h5", staticStyle: { color: "#a783ff" } }, [
          _vm._v("Order Processing"),
        ]),
      ]),
      _vm.prescription.invoice_amount_paid !== null &&
      _vm.prescription.invoice_subtotal !== null
        ? [
            _vm.prescription.invoice_amount_paid ===
            _vm.prescription.invoice_subtotal
              ? [
                  _c("div", { staticClass: "p-3" }, [
                    _vm._v("$" + _vm._s(_vm.prescription.amount.toFixed(2))),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "p-3" }, [
                    _c("span", { staticClass: "strikethrough mr-1" }, [
                      _vm._v(
                        "$" + _vm._s(_vm.prescription.invoice_subtotal / 100)
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        "$" + _vm._s(_vm.prescription.invoice_amount_paid / 100)
                      ),
                    ]),
                  ]),
                ],
          ]
        : [
            _c("div", { staticClass: "p-3" }, [
              _vm._v("$" + _vm._s(_vm.prescription.amount.toFixed(2))),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }